import React, { useEffect, useState } from 'react'
import BackrrLogoLong from '../../assets/backrr_favicon.png'
import PersonLogo from '../../assets/founderImage.jpeg'
import LinkedInIconColor from '../../assets/linkedin_icon.png'
import { Add, Logout, RateReview, ThumbUp } from '@mui/icons-material'
import { useTheme } from '@emotion/react';
import AuthWrapper1 from '../pages/AuthWrapper1';
import { Box, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { B300, BLUEBORDER, WHITEBG } from '../../ui-components/CustomButton';
import StartupLogo from '../../assets/business.png';
import BackrrLogoImage from '../../ui-components/BackrrLogoImage';
import seedImage from '../../assets/stage_icons/seed.png'
import preSeedImage from '../../assets/stage_icons/preSeed.png'
import earlyStageImage from '../../assets/stage_icons/seriesA.png'
import growthImage from '../../assets/stage_icons/seriesC.png'
import ipoImage from '../../assets/stage_icons/ipo.png'
import indiaFlag from '../../assets/india_flag.png'
import avatar from '../../assets/avatar.jpg'
import marketplaceIcon from '../../assets/marketplace.png'
import mobileShoppingIcon from '../../assets/mobile-shopping.png'
import digitalServiceIcon from '../../assets/digital-services.png'
import laptopScreenIcon from '../../assets/laptop-screen.png'
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { ReactComponent as RoundDetailsIcon } from "../../assets/rounds_icons/round_details_icon.svg"


// import CustomPDFThumbnail from '../../ui-components/CustomPDFThumbnail';
import ThumbnailGenerator from '../../ui-components/CustomPDFThumbnail'
import gpsIcon from "../../assets/gps.svg"
import PerfectScrollbar from "react-perfect-scrollbar";
import { FetchProfileDetails, FetchStartupDetails, FetchStatus, SaveFeedback, SaveInterest, SavePersonalInfo, SaveToList, SaveUserType } from '../../api';
import { Button } from '@mui/material'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie';
import { SET_MENU } from '../../store/actions'
import { useMediaQuery } from 'rsuite/esm/useMediaQuery/useMediaQuery'
import { toast } from 'react-hot-toast'
import CustomToast from '../../ui-components/CustomToast'
import ModalComponent from '../../ui-components/ModalComponent'
import NameInvestorPopup from './NameInvestorPopup'
import FeedbackPopup from './FeedbackPopup'
import SaveInterestPopup from './SaveInterestPopup'
import UserTypePopup from './UserTypePopup'
import { isEmptyObject } from '../../utils'
import { Helmet } from 'react-helmet';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AlertDialog from '../../ui-components/popups/AlertPopup'
import MainWrappper from '../pages/MainWrapper'
import RoundDetail from './RoundDetail'

const CardHeader = ({ title, action = null, sx = {} }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"}>
        <Typography variant='h1' fontWeight={700} fontSize={"1rem"} sx={{ ...sx }}>
            {title}
        </Typography>
        {action && <BLUEBORDER
            component="label"
            role={undefined}
            tabIndex={-1}
            sx={{
                minWidth: "0 !important",
                display: "flex",
                gap: '0.5rem',
                backgroundColor: '#FFFAF5',
                border: "0"
            }}
            onClick={action}
        >
            <OpenInNewIcon sx={{ fontSize: 20 }} />
        </BLUEBORDER>}
    </Box>
}

const RichTextDisplay = ({ htmlContent, color = "", textAlign = "start", showMore = true, setShowMore = () => { } }) => {
    return (
        <>
            {htmlContent && htmlContent?.length > 0 ?
                <div className='row rich-text'>
                    <div dangerouslySetInnerHTML={{ __html: htmlContent }} style={{ color: color, textAlign: textAlign }} />
                    {
                        <BLUEBORDER
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            sx={{
                                backgroundColor: '#FFFAF5',
                                border: "0",
                                padding: "0"
                            }}
                            onClick={setShowMore}
                        > <div>{showMore ? 'See more' : 'See less'}</div>
                        </BLUEBORDER>
                    }
                </div>
                : "-"
            }
        </>
    );
};

const PersonDetails = ({ theme, data, index, length }) => {

    const isSmallScreen = (useMediaQuery(theme.breakpoints.down('sm')))[0];
    return <Grid xs={12} sm={12} sx={{
        padding: '0rem', boxShadow: 'none', borderRadius: "8px",
        // border: `1px solid ${theme.palette.customColors.gray300}`,
        overflow: 'hidden', paddingTop: 1, display: 'flex', flexDirection: "row", gap: { xs: "1rem", sm: "1.5rem" },
    }}>
        <Box style={{ flex: 0.3, width: "30%", maxWidth: '5.5rem' }}>
            <img src={data?.image} onError={(ev) => { ev.target.src = avatar }} style={{ width: "100%", aspectRatio: 1, padding: '0', objectFit: 'cover', borderRadius: "20px", marginInline: 'auto', display: 'block', border: `1px solid ${theme.palette.customColors.g75}` }} />
        </Box>
        <Box display={"grid"} gap={{ xs: 0.7, sm: 1 }} sx={{ placeContent: 'flex-start', flex: 1 }}>
            <Grid display={"flex"} alignItems={"center"} justifyContent={"flex-start"} gap={1}>
                {data?.name && <Typography textAlign={"start"} sx={{ fontWeight: 600, marginLeft: 0, fontSize: { xs: "0.9rem", sm: "1rem" } }}>{data?.name} ({data?.type})</Typography>}
                {data?.linkedin_url && <LinkedInIcon sx={{ fontSize: 20 }} style={{ display: 'inline-block', marginInlineEnd: 'auto', color: `${theme.palette.customColors.indigo600}`, cursor: 'pointer' }} onClick={() => { window.open(data?.linkedin_url) }} />}
            </Grid>
            {data?.bio && <Typography textAlign={"start"} sx={{ marginRight: 'auto', marginLeft: 0, color: theme.palette.customColors.g200 }}>{data?.bio}</Typography>}
        </Box>
    </Grid>
}

const StartupDetail = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [startupDetail, setStartupDetail] = useState(null)
    const [overview, setOverview] = useState(null)
    const [userDetail, setUserDetail] = useState(null)
    const [coFoundersArray, setCoFoundersArray] = useState([])

    const [showInitialLogo, setShowInitialLogo] = useState(false)
    const [showMore, setShowMore] = useState(true)

    const [openNamePopup, setOpenNamePopup] = useState(false);
    const [openInterestPopup, setOpenInterestPopup] = useState(false);
    const [openFeedbackPopup, setOpenFeedbackPopup] = useState(false);
    const [openTypePopup, setOpenTypePopup] = useState(false);

    const [enableInterest, setEnableInterest] = useState(true)
    const [enableFeedback, setEnableFeedback] = useState(true)
    const [enableAddToList, setEnableAddToList] = useState(true)

    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null)
    const [alertHeading, setAlertHeading] = useState(null)

    const [buttonClickType, setButtonClickType] = useState(null)

    const [mode, setMode] = useState(null)

    // const slug = Cookies.get("slug")?.trim()

    let pathname = window.location?.pathname
    let splittedPathname = pathname.split("/")
    let slug = splittedPathname[2]
    let document = splittedPathname[3]
    let pitchDeck = splittedPathname[4]

    const [searchParams] = useSearchParams();

    const isTokenAvailable = (Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined)

    const { data: startupDetailsData, isRefetching: isStartupDetailsRefetching, isLoading: isStartupDetailsLoading, refetch: refetchStartupDetails } = FetchStartupDetails(slug)
    const { data: profileDetailsData, isRefetching: isProfileDetailsRefetching, isLoading: isProfileDetailsLoading, refetch: refetchProfileDetails } = isTokenAvailable && FetchProfileDetails()

    const {
        mutateAsync: SavePersonalInfoAsync,
        status: savePersonalInfoStatus,
        error: savePersonalInfoError,
        isLoading: isUpdatingsavePersonalInfo,
        data: savePersonalInfoResponse,
    } = SavePersonalInfo();

    const {
        mutateAsync: SaveToListAsync,
        status: saveToListStatus,
        error: saveToListError,
        isLoading: isUpdatingSaveToList,
        data: saveToListResponse,
    } = SaveToList();

    const {
        mutateAsync: SaveInterestAsync,
        status: saveInterestStatus,
        error: saveInterestError,
        isLoading: isUpdatingSaveInterest,
        data: saveInterestResponse,
    } = SaveInterest();

    const {
        mutateAsync: SaveFeedbackAsync,
        status: saveFeedbackStatus,
        error: saveFeedbackError,
        isLoading: isUpdatingSaveFeedback,
        data: saveFeedbackResponse,
    } = SaveFeedback();

    const {
        mutateAsync: SaveUserTypeAsync,
        status: saveUserTypeStatus,
        error: saveUserTypeError,
        isLoading: isUpdatingSaveUserType,
        data: saveUserTypeResponse,
    } = SaveUserType();

    const {
        mutateAsync: FetchStatusAsync,
        status: fetchStatusStatus,
        error: fetchStatusError,
        isLoading: isUpdatingFetchStatus,
        data: fetchStatusResponse,
    } = FetchStatus();

    const handleNamePopupOpen = () => {
        setOpenNamePopup(true);
    }

    const handleNamePopupClose = () => {
        setOpenNamePopup(false);
    }

    const handleNameInvestor = (values) => {
        // handleNamePopupClose()
        let first_name = values?.first_name
        let last_name = values?.last_name
        savePersonalInfo(first_name, last_name)
    }

    const savePersonalInfo = (first_name, last_name) => {
        let request = {
            first_name: first_name?.trim(),
            last_name: last_name?.trim()
        }
        SavePersonalInfoAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const handleInterestPopupOpen = () => {
        if (isTokenAvailable) {
            if (enableInterest) {
                setButtonClickType("Interest")
                if (userDetail?.first_name?.trim()?.length == 0 || userDetail?.last_name?.trim()?.length == 0) {
                    setOpenNamePopup(true);
                } else {
                    setOpenInterestPopup(true);
                }
            } else {
                setAlertMessage(`You have already shown investment interest in ${startupDetail?.startup_name}. Please wait to hear back from the startup.`)
                setAlertHeading("Investment Interest")
                setShowAlert(true)
            }
        } else {
            handleLogOut()
        }
    }

    const handleInterestPopupClose = () => {
        setOpenInterestPopup(false);
    }

    const handleFeedbackPopupOpen = () => {
        if (isTokenAvailable) {
            if (enableFeedback) {
                setButtonClickType("Feedback")
                if (userDetail?.first_name?.trim()?.length == 0 || userDetail?.last_name?.trim()?.length == 0) {
                    setOpenNamePopup(true);
                } else {
                    setOpenFeedbackPopup(true);
                }
            } else {
                setAlertMessage(`You have already submitted feedback for ${startupDetail?.startup_name}. Please wait to hear back from the startup.`)
                setAlertHeading("Feedback")
                setShowAlert(true)
            }
        } else {
            handleLogOut()
        }
    }
    const handleFeedbackPopupClose = () => {
        setOpenFeedbackPopup(false);
    }

    const handleInterestSave = (values) => {
        let intrested_amount = values?.intrested_amount
        saveInterest(intrested_amount)
    }
    const handleFeedbackSave = (values) => {
        saveFeedback(values)
    }

    const handleTypePopupOpen = () => {
        setOpenTypePopup(true);
    }

    const handleTypePopupClose = () => {
        setOpenTypePopup(false);
    }

    const handleUserType = (values) => {
        // handleTypePopupClose()
        let first_name = values?.first_name
        let last_name = values?.last_name
        let type = values.type
        saveUserType(first_name, last_name, type)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        setMode(searchParams.get("q"));
    }, [window])

    useEffect(() => {
        if (startupDetailsData != null) {
            setStartupDetail(startupDetailsData?.data)
            setOverview(startupDetailsData?.data?.description?.substring(0, 300))
            handleCoFounder(startupDetailsData?.data, startupDetailsData?.data?.cofounder, startupDetailsData?.data?.founder)
        } else {
            setStartupDetail(null)
            setOverview(null)
            setCoFoundersArray([])
        }
    }, [startupDetailsData])

    useEffect(() => {
        if (showMore) {
            setOverview(startupDetailsData?.data?.description?.substring(0, 300))
        } else {
            setOverview(startupDetailsData?.data?.description)
        }
    }, [showMore])

    useEffect(() => {
        setUserDetail(profileDetailsData?.data)
        if (profileDetailsData?.data?.first_name?.trim()?.length == 0 || profileDetailsData?.data?.last_name?.trim()?.length == 0) {
            setTimeout(() => {
                setOpenNamePopup(true);
            }, 1000)
        } else {
            setOpenNamePopup(false)
        }
    }, [profileDetailsData])

    useEffect(() => {
        if (userDetail != null && buttonClickType != null) {
            switch (buttonClickType) {
                case 'Wishlist': {
                    saveToList(userDetail?.first_name, userDetail?.last_name)
                    break
                }
                case 'Interest': {
                    // saveToList(first_name, last_name)
                    setOpenInterestPopup(true);
                    break
                }
                case 'Feedback': {
                    // saveToList(first_name, last_name)
                    setOpenFeedbackPopup(true);
                    break
                }
            }
        }
    }, [userDetail])

    useEffect(() => {
        if (startupDetail != null && userDetail != null) {
            if (isTokenAvailable) {
                fetchStatusOfButtons()
            }
        }
    }, [startupDetail, userDetail])

    const fetchStatusOfButtons = () => {
        let request = {
            startup_id: startupDetail?._id,
            investor_id: userDetail?.investor_id
        }
        FetchStatusAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const handleCoFounder = (startupData, coFounders, founders) => {
        let mergedFounders = []
        founders?.map(item => {
            if (!isEmptyObject(item)) {
                mergedFounders.push({
                    name: item.name,
                    bio: item.bio,
                    image: item.founder_image_path,
                    linkedin_url: item.linkedin_url,
                    type: item.title
                })
            }
        })
        coFounders?.map(item => {
            if (!isEmptyObject(item)) {
                mergedFounders.push({
                    name: item.name,
                    bio: item.bio,
                    image: item.cofounder_image_path,
                    linkedin_url: item.linkedin_url,
                    type: item.title,
                })
            }
        })
        setCoFoundersArray(mergedFounders)
    }

    const handleLogOut = () => {
        dispatch({ type: SET_MENU, opened: false });
        Cookies.remove('jwt', { path: '/' });
        Cookies.remove('is_profile_complete', { path: '/' });
        Cookies.remove('email', { path: '/' });
        navigate('/startup/login');
    };

    const addToMyList = () => {
        setButtonClickType("Wishlist")
        if (userDetail?.first_name?.trim()?.length > 0 && userDetail?.last_name?.trim()?.length > 0) {
            saveToList(userDetail?.first_name, userDetail?.last_name)
        } else {
            // ask for name
            handleNamePopupOpen()
        }
    }

    const saveToList = (first_name, last_name) => {
        let request = {
            startup_id: startupDetail?._id,
            first_name: first_name?.trim(),
            last_name: last_name?.trim()
        }
        SaveToListAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const saveInterest = (intrested_amount) => {
        let request = {
            startup_id: startupDetail?._id,
            intrested_amount: intrested_amount
        }
        SaveInterestAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const saveFeedback = (values) => {
        let request = {
            startup_id: startupDetail?._id,
            investor_id: userDetail?.investor_id,
            ratings: {
                problem: values?.problem,
                market: values?.market,
                product: values?.product,
                team: values?.team,
                traction: values?.traction
            },
            investible: values?.invest,
            feedback: values?.feedback
        }
        SaveFeedbackAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const saveUserType = (first_name, last_name, type) => {
        let request = {
            first_name: first_name?.trim(),
            last_name: last_name?.trim(),
            type: type
        }
        SaveUserTypeAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    useEffect(() => {
        if (savePersonalInfoResponse && savePersonalInfoResponse?.data?.statusCode == 200) {
            handleNamePopupClose()
            refetchProfileDetails()
        } else {
            savePersonalInfoResponse?.data?.message && toast.error(<CustomToast message={savePersonalInfoResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
    }, [savePersonalInfoResponse?.data?.statusCode]);

    useEffect(() => {
        if (saveToListResponse && saveToListResponse?.data?.statusCode == 200) {
            toast.success(<CustomToast message={saveToListResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        } else {
            saveToListResponse?.data?.message && toast.error(<CustomToast message={saveToListResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
        handleNamePopupClose()
        setButtonClickType(null)
        // refetchStartupDetails()
        if (isTokenAvailable) {
            fetchStatusOfButtons()
        }
    }, [saveToListResponse?.data?.statusCode]);

    useEffect(() => {
        if (saveInterestResponse && saveInterestResponse?.data?.statusCode == 200) {
            toast.success(<CustomToast message={saveInterestResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        } else {
            saveInterestResponse?.data?.message && toast.error(<CustomToast message={saveInterestResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
        handleInterestPopupClose()
        setButtonClickType(null)
        // refetchStartupDetails()
        if (isTokenAvailable) {
            fetchStatusOfButtons()
        }
    }, [saveInterestResponse?.data?.statusCode]);

    useEffect(() => {
        if (saveFeedbackResponse && saveFeedbackResponse?.data?.statusCode == 200) {
            toast.success(<CustomToast message={saveFeedbackResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        } else {
            saveFeedbackResponse?.data?.message && toast.error(<CustomToast message={saveFeedbackResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
        handleFeedbackPopupClose()
        setButtonClickType(null)
        // refetchStartupDetails()
        if (isTokenAvailable) {
            fetchStatusOfButtons()
        }
    }, [saveFeedbackResponse?.data?.statusCode]);

    useEffect(() => {
        if (saveUserTypeResponse && saveUserTypeResponse?.data?.statusCode == 200) {
            toast.success(<CustomToast message={saveUserTypeResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        } else {
            saveUserTypeResponse?.data?.message && toast.error(<CustomToast message={saveUserTypeResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
        handleTypePopupClose()
        redirectToDashdoard()
        // refetchStartupDetails()
        if (isTokenAvailable) {
            fetchStatusOfButtons()
        }
    }, [saveUserTypeResponse?.data?.statusCode]);

    useEffect(() => {
        if (fetchStatusResponse && fetchStatusResponse?.data?.statusCode == 200) {
            setEnableInterest(fetchStatusResponse?.data?.investorInfo == null)
            setEnableFeedback(fetchStatusResponse?.data?.feedback == null)
        } else {
            // fetchStatusResponse?.data?.message && toast.error(<CustomToast message={fetchStatusResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            //     duration: 4000,
            //     position: "top-right",
            //     style: {
            //         padding: "15px 30px",
            //     },
            // });
        }
    }, [fetchStatusResponse?.data?.statusCode]);

    const redirectToDashdoard = () => {

    }

    const getCategoryIcon = () => {
        switch (startupDetail?.category_info) {
            case "Technology": {
                // return <MemoryOutlined sx={{ fontSize: { xs: '4.3rem', md: '4.2rem' }, paddingTop: "1rem", marginLeft: 'auto', transform: `translate(8px, 8px)` }} />
                return <img src={laptopScreenIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto' }} alt='icon' />
            }
            case "Consumer Brand": {
                // return <StorefrontOutlined sx={{ fontSize: { xs: '4.3rem', md: '4.2rem' }, paddingTop: "1rem", marginLeft: 'auto', transform: `translate(8px, 8px)` }} />
                return <img src={mobileShoppingIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto' }} alt='icon' />

            }
            case "Marketplace": {
                // return <LocalMallOutlined sx={{ fontSize: { xs: '4.3rem', md: '4.2rem' }, paddingTop: "1rem", marginLeft: 'auto', transform: `translate(8px, 8px)` }} />
                return <img src={marketplaceIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto', paddingRight: "0.6rem" }} alt='icon' />
            }

            case "Services": {
                // return <MiscellaneousServicesOutlined sx={{ fontSize: { xs: '4.3rem', md: '4.2rem' }, paddingTop: "1rem", marginLeft: 'auto', transform: `translate(8px, 8px)` }} />
                return <img src={digitalServiceIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto', transform: 'rotateY(180deg)' }} alt='icon' />
            }
        }
    }

    const getStageIcon = () => {
        switch (startupDetail?.stage_info?.config_values?.name) {
            case "Pre-Seed": {
                return <img src={preSeedImage} rel='preload' style={{ width: "5rem", height: "1.649rem", paddingTop: "3.5rem", marginLeft: 'auto' }} />
            }
            case "Seed": {
                return <img src={seedImage} rel='preload' style={{ width: "5rem", height: "2.649rem", paddingTop: "2.5rem", marginLeft: 'auto' }} />
            }
            case "Early Stage": {
                return <img src={earlyStageImage} rel='preload' style={{ width: "5rem", height: "3.649rem", paddingTop: "1.5rem", marginLeft: 'auto' }} />
            }
            case "Growth": {
                return <img src={growthImage} rel='preload' style={{ width: "5rem", height: "5.2rem", paddingTop: "0rem", marginLeft: 'auto' }} />
            }
            case "Expansion": {
                return <img src={ipoImage} rel='preload' style={{ width: "5rem", paddingTop: "1rem", marginLeft: 'auto' }} />
            }
        }
    }

    const ButtonsRow = () => {
        return <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} gap={2}>
            {/* <B300 onClick={addToMyList} startIcon={<Add fontSize='1.25rem' />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize:'0.7rem' }} style={{ lineHeight:'13px'}}>
                Add to my list
            </B300> */}
            <B300 onClick={handleInterestPopupOpen} startIcon={<ThumbUp sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
                Show Interest
            </B300>
            <B300 onClick={handleFeedbackPopupOpen} startIcon={<RateReview sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
                Submit Feedback
            </B300>
            {/* {console.log(startupDetail,"startupDetail")} */}
            {startupDetail?.startup_rounds_info?.length > 0 && <B300 onClick={() => {
                if (isTokenAvailable) {
                    if (userDetail?.first_name?.trim()?.length == 0 || userDetail?.last_name?.trim()?.length == 0) {
                        setOpenNamePopup(true);
                    } else {
                        window.history.replaceState("", "", `/startup/${slug}?q=funding-round`); setMode("funding-round")
                    }
                } else {
                    handleLogOut()
                }
            }}
                startIcon={<RoundDetailsIcon fill={"#FFF"} height={20} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>

                Round Details
            </B300>}

        </Grid>
    }
    const commonCardStyles = {
        paddingTop: '1rem',
        // paddingBottom: '1rem',
        boxShadow: 'none',
        background: 'transparent',
        overflow: 'hidden',
        border: `1px solid ${theme.palette.customColors.indigo600}`,
        borderRadius: '15px',
    };

    return (

        <AuthWrapper1 sx={{ height: '100%' }} theme={theme}>
            <MainWrappper profileData={userDetail} startupData={startupDetail} theme={theme}>
                <Helmet>
                    <title>{startupDetail != null ? startupDetail?.startup_name + " | Backrr" : "Backrr"}</title>
                    <meta name="description" content={startupDetail?.problem_solving} />
                    <meta property="og:title" content={startupDetail != null ? startupDetail?.startup_name + " | Backrr" : "Backrr"} />
                    <meta property="og:description" content={startupDetail?.problem_solving} />
                </Helmet>

                <main>
                    {isTokenAvailable && <Grid position={'fixed'} right={10} top={10} zIndex={100}>
                        <IconButton onClick={handleLogOut}><Logout /></IconButton>
                    </Grid>}
                    {
                        isStartupDetailsLoading ?
                            <Grid xs={12} container display={"block"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }} sx={{
                                maxWidth: '900px !important',
                                marginInline: 'auto',
                                height: '100%',
                                paddingTop: '0.5rem !important'
                            }}>
                                <Grid container xs={12} alignItems={"center"} spacing={2} pb={2} marginInline={"auto"} position={"sticky"} top={0} bgcolor={"#FFFAF5"} sx={{ zIndex: 1 }}>
                                    <Grid item xs={12} pl={"0 !important"}>
                                        <div className='skeleton' style={{ width: "6rem", height: "6rem", borderRadius: '3rem', marginInline: 'auto', display: "block" }} />
                                    </Grid>
                                    <Grid xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1} marginTop={1}>
                                        <div className='skeleton' style={{ marginInline: 'auto', width: '6rem', height: '2rem' }} />
                                    </Grid>
                                    <div className='skeleton' style={{ marginInline: 'auto', height: '0.8rem', width: '6rem', marginTop: 2 }} />

                                    <Grid spacing={2} marginTop={1} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                        <div className='skeleton' style={{ display: 'inline-block', width: "3rem", height: "3rem" }} />
                                        <div className='skeleton' style={{ display: 'inline-block', width: "3rem", height: "3rem" }} />
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} alignItems={"center"} marginInline={"auto"} display={"flex"} bgcolor={"#FFFAF5"}>
                                    <Grid container xs={12} alignItems={"center"} marginInline={"auto"} display={"flex"} padding={{ xs: "0rem 1rem 0rem 1rem", sm: "0rem 1rem 0rem 1rem" }}>
                                        <div className='skeleton' style={{ marginInline: 'auto', padding: "0 1rem 0 1rem", marginTop: '0.1rem', width: '10rem', height: '2rem' }} />
                                    </Grid>
                                    <Grid item sx={{
                                        width: '100%', minHeight: "40rem", height: '100%',
                                        borderRadius: '8px', padding: { xs: "1rem !important", md: "1rem 2rem !important" },
                                        gap: "1rem"
                                    }}>

                                        <ButtonsRow />
                                        <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', borderRadius: '15px', marginTop: '25px' }}>
                                            <div className='skeleton' style={{ height: '10rem', width: '100%', borderRadius: '15px', }} />
                                        </Card>
                                        <Grid item xs={12} md={12} marginInline={"auto"} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={2} marginTop={2.5} >
                                            <Grid item xs={6} >
                                                <button className='skeleton' style={{
                                                    height: "100%",
                                                    padding: "1rem 0rem 0rem 1rem",
                                                    cursor: 'default',
                                                    borderRadius: "8px",
                                                    width: '95%',
                                                    marginLeft: '20px'
                                                }}>
                                                    <div className='skeleton' style={{ height: '5rem', borderRadius: '8px', }} />
                                                </button>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <button className='skeleton' style={{
                                                    height: "100%",
                                                    padding: "1rem 0rem 0rem 1rem",
                                                    cursor: 'default',
                                                    borderRadius: "8px",
                                                    width: '95%'
                                                }}>
                                                    <div className='skeleton' style={{ height: '5rem', borderRadius: '8px', }} />
                                                </button>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} paddingLeft={"0 !important"} paddingRight={0} marginTop={2.5}>
                                            <Card sx={{ ...commonCardStyles, ...{ border: 0 } }}>
                                                <div className='skeleton' style={{ height: '20rem', width: '95%', borderRadius: '15px', marginLeft: '20px' }} />
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} paddingLeft={"0 !important"} paddingRight={0} marginTop={2.5}>
                                            <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 1, borderRadius: '15px', marginTop: '20px' }}>
                                                <div className='skeleton' style={{ height: '20rem', width: '100%', borderRadius: '15px' }} />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} paddingLeft={"0 !important"} paddingRight={"0"} marginTop={2.5}>
                                            <Card sx={{ ...commonCardStyles, ...{ border: 0 } }}>
                                                <div className='skeleton' style={{ height: '20rem', width: '95%', borderRadius: '15px', marginLeft: '20px' }} />
                                            </Card>
                                        </Grid>

                                    </Grid>

                                </Grid>
                            </Grid> :
                            <Grid xs={12} container display={"block"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }} sx={{
                                maxWidth: '900px !important',
                                marginInline: 'auto',
                                height: '100%',
                                paddingTop: '0.5rem !important'
                            }}>
                                <Grid container xs={12} alignItems={"center"} spacing={2} pb={2} marginInline={"auto"} position={"sticky"} top={0} bgcolor={"#FFFAF5"} sx={{ zIndex: 1 }}>
                                    <Grid item xs={12} pl={"0 !important"}>
                                        {
                                            showInitialLogo ? <div style={{ width: "4.5rem", height: "4.5rem", borderRadius: '2.4rem', marginInline: 'auto', display: "block", justifyContent: 'center', alignItems: 'center', backgroundColor: "#007bff", alignContent: 'center' }}>
                                                <Typography variant='h1' sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" }, textAlign: 'center', marginInline: 'auto' }} color={'white'}>{startupDetail?.startup_name?.split(" ")[0]?.substring(0, 1)}{startupDetail?.startup_name?.split(" ")[1]?.substring(0, 1)}</Typography>
                                            </div>
                                                : <img src={startupDetail != null ? startupDetail?.startup_logo_path : null} onLoad={() => { setShowInitialLogo(false) }} onError={() => { setShowInitialLogo(true) }} style={{ width: "6rem", height: "6rem", borderRadius: '8px', marginInline: 'auto', display: "block" }} />
                                        }
                                    </Grid>
                                    <Grid xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                        {startupDetail?.startup_name && <Typography variant='title' sx={{ fontSize: { xs: "1.5rem", md: "1.50rem" }, textAlign: 'center', marginInline: 'auto' }}>{startupDetail?.startup_name}</Typography>}
                                    </Grid>
                                    {(startupDetail?.city || startupDetail?.country) && <Typography variant='body2' sx={{ fontSize: { xs: "0.8rem", md: "0.8rem" }, textAlign: 'center', marginInline: 'auto' }}>{startupDetail?.city}{startupDetail?.city && startupDetail?.country && ","} {startupDetail?.country}
                                        {startupDetail?.country?.trim()?.length > 0 && startupDetail?.country?.trim() == "India" && <span>
                                            <img src={indiaFlag} rel='preload' style={{ width: "1.2rem", marginLeft: '5px' }} />
                                        </span>}
                                    </Typography>}

                                    <Grid spacing={2} marginTop={1} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                        {startupDetail?.linkedin_url && <button type='button' style={{ backgroundColor: 'transparent', padding: 0 }}><LinkedInIcon sx={{ fontSize: 30, borderRadius: 10 }} style={{ display: 'inline-block' }} onClick={() => { window.open(startupDetail?.linkedin_url) }} /></button>}
                                        {startupDetail?.website_url && <button type='button' style={{ backgroundColor: 'transparent', padding: 0 }}><LanguageIcon sx={{ fontSize: 30 }} style={{ display: 'inline-block' }} onClick={() => { window.open(startupDetail?.website_url) }} /></button>}
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} alignItems={"center"} marginInline={"auto"} display={"flex"} bgcolor={"#FFFAF5"}>
                                    <Grid container xs={12} alignItems={"center"} marginInline={"auto"} display={"flex"} padding={{ xs: "0rem 1rem 0rem 1rem", sm: "0rem 1rem 0rem 1rem" }}>
                                        {startupDetail?.problem_solving && <Typography fontSize={"0.9rem"} variant='bodyTextRegular' sx={{ textAlign: 'center', marginInline: 'auto', padding: "0 1rem 0 1rem", marginTop: '0.1rem', maxWidth: { xs: "100%", sm: '50vw', lg: '30vw' }, color: theme.palette.customColors.g200 }}>{startupDetail?.problem_solving}</Typography>}
                                    </Grid>
                                    <Grid item sx={{
                                        width: '100%', minHeight: "40rem", height: '100%',
                                        // marginTop: "1rem", 
                                        borderRadius: '8px', padding: { xs: "1rem !important", md: "1rem 2rem !important" },
                                        //  border: `1px solid ${theme.palette.customColors.g75}`,
                                        gap: "1rem"
                                    }}>

                                        <ButtonsRow />
                                        {startupDetail?.description && <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', border: `1px solid ${theme.palette.customColors.indigo600}`, borderRadius: '15px', marginTop: '25px' }}>
                                            <CardHeader title={"Overview"} />
                                            <RichTextDisplay htmlContent={overview} color={theme.palette.customColors.g200} showMore={showMore} setShowMore={() => { setShowMore(prev => !prev) }} textAlign='justify' />
                                        </Card>}
                                        <Grid item xs={12} md={12} marginInline={"auto"} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={2} marginTop={2.5} >
                                            {startupDetail?.category_info && <Grid item xs={6} >
                                                <button style={{
                                                    minHeight: { md: "7rem", xs: "5rem" },
                                                    height: "100%",
                                                    maxHeight: { md: "7rem", xs: "5rem" },
                                                    width: "100%",
                                                    // padding: "0.5rem 0rem 6rem 0.5rem",
                                                    padding: "1rem 0rem 0rem 1rem",
                                                    background: "transparent",
                                                    fontSize: "0.875rem",
                                                    cursor: 'default',
                                                    border: `1px solid ${theme.palette.customColors.indigo600}`,
                                                    borderRadius: "8px",
                                                    overflow: "hidden",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}>
                                                    <div>
                                                        <Typography fontWeight={700} fontSize={"1rem"} textAlign={'left'}>Sector</Typography>
                                                        <div style={{ fontWeight: 600, fontSize: "0.8rem", textAlign: 'left', color: theme.palette.customColors.g200 }}>
                                                            {startupDetail?.category_info} ({startupDetail?.sub_category})
                                                        </div>
                                                    </div>
                                                    {getCategoryIcon()}
                                                    {/* <img src={getCategoryIcon()} rel='preload' style={{ width: "6rem", paddingTop: "1rem", marginLeft: 'auto' }} /> */}
                                                </button>
                                            </Grid>}
                                            {startupDetail?.stage_info && <Grid item xs={6} >
                                                {/* <CardHeader title={"Stage"} /> */}
                                                <button style={{
                                                    minHeight: { md: "7rem", xs: "5rem" },
                                                    height: "100%",
                                                    maxHeight: { md: "7rem", xs: "5rem" },
                                                    width: "100%",
                                                    // padding: "0.5rem 0rem 6rem 0.5rem",
                                                    padding: "1rem 0rem 0rem 1rem",
                                                    background: "transparent",
                                                    fontSize: "0.875rem",
                                                    cursor: 'default',
                                                    border: `1px solid ${theme.palette.customColors.indigo600}`,
                                                    borderRadius: "8px",
                                                    overflow: "hidden",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}>
                                                    <div>
                                                        <Typography fontWeight={700} fontSize={"1rem"} textAlign={'left'}>Stage</Typography>
                                                        <div style={{ fontWeight: 600, fontSize: "0.8rem", textAlign: 'left', color: theme.palette.customColors.g200 }}>
                                                            {startupDetail?.stage_info?.config_values?.name}
                                                        </div>
                                                    </div>
                                                    {getStageIcon()}
                                                    {/* <img src={startupDetail?.stage_images + "" + startupDetail?.stage_info?.config_values?.image} rel='preload' style={{ width: "6rem", paddingTop: "1rem", marginLeft: 'auto' }} /> */}
                                                </button>
                                            </Grid>}
                                        </Grid>

                                        {startupDetail?.elevator_pitch_path && <Grid item xs={12} paddingLeft={"0 !important"} paddingRight={0} marginTop={2.5}>
                                            <Card sx={{ ...commonCardStyles }}>
                                                <CardHeader sx={{ paddingLeft: "1rem" }} title={"Video Pitch"}
                                                // sx={{ fontSize: "1rem" }}
                                                />
                                                <ThumbnailGenerator type="video" url={startupDetail?.elevator_pitch_path} />
                                            </Card>
                                        </Grid>}

                                        {coFoundersArray?.length > 0 && <Grid item xs={12} paddingLeft={"0 !important"} paddingRight={0} marginTop={2.5}>
                                            <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 1, border: `1px solid ${theme.palette.customColors.indigo600}`, borderRadius: '15px', marginTop: '20px' }}>
                                                <CardHeader title={"Founders"} />
                                                <Grid container xs={12} gap={1}>
                                                    {
                                                        coFoundersArray?.map((person, index) => {
                                                            return <PersonDetails theme={theme} data={person} key={index} index={index} length={coFoundersArray?.length} />
                                                        })
                                                    }
                                                </Grid>
                                            </Card>
                                        </Grid>}
                                        {startupDetail?.pitch_deck_path && <Grid item xs={12} paddingLeft={"0 !important"} paddingRight={"0"} marginTop={2.5}>
                                            <Card sx={{ ...commonCardStyles }}>
                                                <CardHeader sx={{ paddingLeft: "1rem" }} title={"Pitch Deck"} action={() => { navigate(`/startup/${slug}/d/pitchdeck`) }} />
                                                <ThumbnailGenerator url={startupDetail?.pitch_deck_path} thumbnail={startupDetail?.pitch_deck_thumbnail_path} action={() => { navigate(`/startup/${slug}/d/pitchdeck`) }} />
                                            </Card>
                                        </Grid>}
                                        {/* <ButtonsRow /> */}

                                    </Grid>

                                </Grid>
                            </Grid>
                    }
                    <Dialog fullScreen={true} open={mode === "funding-round" && startupDetail?.startup_rounds_info?.length > 0} onClose={() => { window.history.replaceState("", "", `/startup/${slug}`); setMode(null) }} sx={{}}>
                        <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.customColors.gray300}` }}>
                            <Grid sx={{ position: 'sticky', padding: "0", backgroundColor: '#FFF', zIndex: 1, marginBottom: '0.5rem' }} display={'flex'} alignItems={'center'}>
                                <Grid style={{ flex: 1 }}>
                                    <Typography fontWeight={700} fontSize={"1.3rem"} textAlign={'center'}>Round Details</Typography>
                                </Grid>
                                <IconButton disableRipple={true} sx={{ padding: 0 }}>
                                    <CloseIcon width={40} height={40} style={{ marginRight: "-15px" }} onClick={() => { window.history.replaceState("", "", `/startup/${slug}`); setMode(null) }} />
                                </IconButton>
                            </Grid>
                            <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} gap={2}>
                                <B300 onClick={handleInterestPopupOpen} startIcon={<ThumbUp sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
                                    Show Interest
                                </B300>
                                <B300 onClick={handleFeedbackPopupOpen} startIcon={<RateReview sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
                                    Submit Feedback
                                </B300>
                            </Grid>
                        </DialogTitle>
                        <DialogContent sx={{ padding: 0 }}>
                            <RoundDetail data={startupDetail?.startup_rounds_info?.length > 0 && startupDetail?.startup_rounds_info[0]} startupDetail={startupDetail} handleClose={() => { window.history.replaceState("", "", `/startup/${slug}`); setMode(null) }} />
                        </DialogContent>
                    </Dialog>
                    <ModalComponent open={openInterestPopup} onClose={handleInterestPopupClose} style={{ maxWidth: 640, width: "100%" }}>
                        <SaveInterestPopup data={userDetail} handleClose={handleInterestPopupClose} handleSubmit={handleInterestSave} />
                    </ModalComponent>
                    <ModalComponent open={openFeedbackPopup} onClose={handleFeedbackPopupClose} style={{ maxWidth: 640, width: "100%" }}>
                        <FeedbackPopup data={userDetail} handleClose={handleFeedbackPopupClose} handleSubmit={handleFeedbackSave} />
                    </ModalComponent>
                    <ModalComponent open={openTypePopup} onClose={handleTypePopupClose} style={{ maxWidth: 640, width: "100%" }}>
                        <UserTypePopup data={userDetail} handleClose={handleTypePopupClose} handleSubmit={handleUserType} />
                    </ModalComponent>
                    <AlertDialog open={showAlert} handleClose={() => { setShowAlert(false); setAlertMessage(null); setAlertHeading(null) }} heading={alertHeading} subheading={alertMessage} />
                    <ModalComponent open={openNamePopup} onClose={() => { }} style={{ maxWidth: 640, width: "100%" }}>
                        <NameInvestorPopup handleClose={handleNamePopupClose} handleSubmit={handleNameInvestor} />
                    </ModalComponent>
                </main>
            </MainWrappper>
        </AuthWrapper1>
    )
}

export default StartupDetail
import { useTheme } from "@emotion/react";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../ui-components/CustomInputField";
import { B300 } from "../../ui-components/CustomButton";
import * as Yup from "yup";

const NameInvestorPopup = ({ handleClose, handleSubmit }) => {
  const theme = useTheme();

  const validations = Yup.object(
    {
      first_name: Yup.string("Please enter First Name").required("Please enter First Name").trim(),
      last_name: Yup.string("Please enter Last Name").required("Please enter Last Name").trim(),
    }
  )

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    enableReinitialize: true,
    validationSchema: validations,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values);
    },
  });
  return (
    <Paper
      sx={{
        background: theme.palette.customColors.whiteBG,
        borderRadius: "20px",
      }}
    >
      {/* <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
        <CloseIcon width={40} height={40} onClick={handleClose} />
      </IconButton> */}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                }}
              >
                Enter Your Name to Continue
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: theme.palette.customColors.g200,
                }}
              >
                Add name to customize your experience
              </Typography> */}
            </Grid>
            <Grid item xs={12}>
              <CustomInputField
                name="first_name"
                label={"First Name"}
                value={formik.values.first_name}
                onChange={(e) => {
                  formik.setFieldValue("first_name", e.target.value);
                }}
                sx={{ marginBottom: 2 }}
                hasError = {formik.errors.first_name}
                errorMessage = {formik.errors.first_name}
              />
              <CustomInputField
                name="last_name"
                label={"Last Name"}
                value={formik.values.last_name}
                onChange={(e) => {
                  formik.setFieldValue("last_name", e.target.value);
                }}
                hasError = {formik.errors.last_name}
                errorMessage = {formik.errors.last_name}
              />
            </Grid>
            <Grid item xs={12}>
              <B300
                type="submit"
                fullWidth
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" }}
              >
                Save
              </B300>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default NameInvestorPopup;

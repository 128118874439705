import React, { useEffect, useState } from 'react'
import { Add, Logout, ThumbUp, RateReview, ArrowBack } from '@mui/icons-material'
import { useTheme } from '@emotion/react';
import AuthWrapper1 from '../pages/AuthWrapper1';
import { Box, Card, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { B300, WHITEBG } from '../../ui-components/CustomButton';
import BackrrLogoImage from '../../ui-components/BackrrLogoImage';
import { FetchProfileDetails, FetchStartupDetails, FetchStartupPitchDeck, FetchStatus, SaveFeedback, SaveInterest, SavePersonalInfo, SaveToList, SaveUserType } from '../../api';
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie';
import { SET_MENU } from '../../store/actions'
import { toast } from 'react-hot-toast'
import CustomToast from '../../ui-components/CustomToast'
import ModalComponent from '../../ui-components/ModalComponent'
import NameInvestorPopup from '../startupDetail/NameInvestorPopup';
import SaveInterestPopup from '../startupDetail/SaveInterestPopup';
import UserTypePopup from '../startupDetail/UserTypePopup';
import { Helmet } from 'react-helmet';
import StartupLogo from '../../assets/business.png';
import FeedbackPopup from '../startupDetail/FeedbackPopup'
import AlertDialog from '../../ui-components/popups/AlertPopup';
import MainWrappper from '../pages/MainWrapper';
import { Document, Page, pdfjs } from 'react-pdf';
import { useWindowSize } from '../../layout/hooks';
import NoData from '../../ui-components/NoData';
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
// import { ReactComponent as FullscreenIcon } from "../../assets/fullscreen-svgrepo-com.svg"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const PitchDeck = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    //     'pdfjs-dist/build/pdf.worker.min.mjs',
    //     import.meta.url,
    //   ).toString();

    const [userDetail, setUserDetail] = useState(null)
    const [pdfUrl, setPdfUrl] = useState(null)

    const [openNamePopup, setOpenNamePopup] = useState(false);
    const [openInterestPopup, setOpenInterestPopup] = useState(false);
    const [openFeedbackPopup, setOpenFeedbackPopup] = useState(false);
    const [openTypePopup, setOpenTypePopup] = useState(false);
    const [type, setType] = useState(null)

    const [showInitialLogo, setShowInitialLogo] = useState(false)
    const [startupDetail, setStartupDetail] = useState(null)

    const [enableInterest, setEnableInterest] = useState(true)
    const [enableFeedback, setEnableFeedback] = useState(true)
    const [enableAddToList, setEnableAddToList] = useState(true)

    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null)
    const [alertHeading, setAlertHeading] = useState(null)

    const [buttonClickType, setButtonClickType] = useState(null)

    const [pdfLoading, setPdfLoading] = useState(true)
    const [openFullScreen, setOpenFullScreen] = useState(false)
    const [loadError, setLoadError] = useState(false)

    const [numPages, setNumPages] = useState(null);
    const [pageWidth, setPageWidth] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [width, height] = useWindowSize();

    // const slug = Cookies.get("slug")?.trim()
    let pathname = window.location?.pathname
    let splittedPathname = pathname.split("/")
    let slug = splittedPathname[2]
    // let document = splittedPathname[3]
    let pitchDeck = splittedPathname[4]

    // let pdfUrl = "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    const { data: fullStartupDetailsData, isRefetching: isFullStartupDetailsRefetching, isLoading: isFullStartupDetailsLoading, refetch: refetchFullStartupDetails } = FetchStartupDetails(slug)
    const { data: startupDetailsData, isRefetching: isStartupDetailsRefetching, isLoading: isStartupDetailsLoading, refetch: refetchStartupDetails } = FetchStartupPitchDeck(slug)
    const { data: profileDetailsData, isRefetching: isProfileDetailsRefetching, isLoading: isProfileDetailsLoading, refetch: refetchProfileDetails } = FetchProfileDetails()
    // console.log(startupDetailsData, "startupDetailsData")

    localStorage.setItem("jwt", Cookies.get("jwt"))

    useEffect(() => {
        window.scrollTo(0, 0)
        setPdfLoading(true)
        setPdfUrl(null)
    }, [])

    const {
        mutateAsync: SavePersonalInfoAsync,
        status: savePersonalInfoStatus,
        error: savePersonalInfoError,
        isLoading: isUpdatingsavePersonalInfo,
        data: savePersonalInfoResponse,
    } = SavePersonalInfo();

    const {
        mutateAsync: SaveToListAsync,
        status: saveToListStatus,
        error: saveToListError,
        isLoading: isUpdatingSaveToList,
        data: saveToListResponse,
    } = SaveToList();

    const {
        mutateAsync: SaveInterestAsync,
        status: saveInterestStatus,
        error: saveInterestError,
        isLoading: isUpdatingSaveInterest,
        data: saveInterestResponse,
    } = SaveInterest();

    const {
        mutateAsync: SaveFeedbackAsync,
        status: saveFeedbackStatus,
        error: saveFeedbackError,
        isLoading: isUpdatingSaveFeedback,
        data: saveFeedbackResponse,
    } = SaveFeedback();

    const {
        mutateAsync: SaveUserTypeAsync,
        status: saveUserTypeStatus,
        error: saveUserTypeError,
        isLoading: isUpdatingSaveUserType,
        data: saveUserTypeResponse,
    } = SaveUserType();

    const {
        mutateAsync: FetchStatusAsync,
        status: fetchStatusStatus,
        error: fetchStatusError,
        isLoading: isUpdatingFetchStatus,
        data: fetchStatusResponse,
    } = FetchStatus();

    const handleNamePopupOpen = () => {
        setOpenNamePopup(true);
    }

    const handleNamePopupClose = () => {
        setOpenNamePopup(false);
    }

    const handleNameInvestor = (values) => {
        // handleNamePopupClose()
        let first_name = values?.first_name
        let last_name = values?.last_name
        savePersonalInfo(first_name, last_name)
    }

    const savePersonalInfo = (first_name, last_name) => {
        let request = {
            first_name: first_name?.trim(),
            last_name: last_name?.trim()
        }
        SavePersonalInfoAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const handleInterestPopupOpen = () => {
        // setOpenInterestPopup(true);
        if (enableInterest) {
            setButtonClickType("Interest")
            if (profileDetailsData?.data?.first_name?.trim()?.length == 0 || profileDetailsData?.data?.last_name?.trim()?.length == 0) {
                setOpenNamePopup(true);
            } else {
                setOpenInterestPopup(true);
            }
        } else {
            setAlertMessage(`You have already shown investment interest in ${startupDetail?.startup_name}. Please wait to hear back from the startup.`)
            setAlertHeading("Investment Interest")
            setShowAlert(true)
        }
    }

    const handleInterestPopupClose = () => {
        setOpenInterestPopup(false);
    }

    const handleFeedbackPopupOpen = () => {
        if (enableFeedback) {
            setButtonClickType("Feedback")
            if (profileDetailsData?.data?.first_name?.trim()?.length == 0 || profileDetailsData?.data?.last_name?.trim()?.length == 0) {
                setOpenNamePopup(true);
            } else {
                setOpenFeedbackPopup(true);
            }
        } else {
            setAlertMessage(`You have already submitted feedback for ${startupDetail?.startup_name}. Please wait to hear back from the startup.`)
            setAlertHeading("Feedback")
            setShowAlert(true)
        }
    }
    const handleFeedbackPopupClose = () => {
        setOpenFeedbackPopup(false);
    }

    const handleInterestSave = (values) => {
        let intrested_amount = values?.intrested_amount
        saveInterest(intrested_amount)
    }

    const handleFeedbackSave = (values) => {
        saveFeedback(values)
    }

    const handleTypePopupOpen = () => {
        setOpenTypePopup(true);
    }

    const handleTypePopupClose = () => {
        setOpenTypePopup(false);
    }

    const handleUserType = (values) => {
        setType(values.type)
        let first_name = values?.first_name
        let last_name = values?.last_name
        let type = values.type
        saveUserType(first_name, last_name, type)
    }

    useEffect(() => {
        if (fullStartupDetailsData != null) {
            setStartupDetail(fullStartupDetailsData?.data)
        } else {
            setStartupDetail(null)
        }
    }, [fullStartupDetailsData])

    useEffect(() => {
        if (startupDetailsData != null) {
            let pdf_url = startupDetailsData?.data?.pitch_deck_url
            setPdfUrl(pdf_url)
            setPdfLoading(false)
        } else {
            setPdfUrl(null)
        }
    }, [startupDetailsData])

    useEffect(() => {
        setUserDetail(profileDetailsData?.data)
        if (profileDetailsData?.data?.first_name?.trim()?.length == 0 || profileDetailsData?.data?.last_name?.trim()?.length == 0) {
            setTimeout(() => {
                setOpenNamePopup(true);
            }, 1000)
        } else {
            setOpenNamePopup(false)
        }
    }, [profileDetailsData])

    useEffect(() => {
        if (userDetail != null && buttonClickType != null) {
            switch (buttonClickType) {
                case 'Wishlist': {
                    saveToList(userDetail?.first_name, userDetail?.last_name)
                    break
                }
                case 'Interest': {
                    // saveToList(first_name, last_name)
                    setOpenInterestPopup(true);
                    break
                }
                case 'Feedback': {
                    // saveToList(first_name, last_name)
                    setOpenFeedbackPopup(true);
                    break
                }
            }
        }
    }, [userDetail])

    useEffect(() => {
        if (startupDetail != null && userDetail != null) {
            fetchStatusOfButtons()
        }
    }, [startupDetail, userDetail])

    const fetchStatusOfButtons = () => {
        let request = {
            startup_id: startupDetail?._id,
            investor_id: userDetail?.investor_id
        }
        FetchStatusAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const handleLogOut = () => {
        dispatch({ type: SET_MENU, opened: false });
        Cookies.remove('jwt', { path: '/' });
        Cookies.remove('is_profile_complete', { path: '/' });
        Cookies.remove('email', { path: '/' });
        navigate('/startup/login');
    };

    const addToMyList = () => {
        if (userDetail?.first_name?.trim()?.length > 0 && userDetail?.last_name?.trim()?.length > 0) {
            saveToList(userDetail?.first_name, userDetail?.last_name)
        } else {
            // ask for name
            handleNamePopupOpen()
        }
    }

    const saveToList = (first_name, last_name) => {
        let request = {
            startup_id: startupDetailsData?.data?.startup_id,
            first_name: first_name?.trim(),
            last_name: last_name?.trim()
        }
        SaveToListAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const saveInterest = (intrested_amount) => {
        let request = {
            startup_id: startupDetailsData?.data?.startup_id,
            intrested_amount: intrested_amount
        }
        SaveInterestAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const saveFeedback = (values) => {
        let request = {
            startup_id: startupDetail?._id,
            investor_id: userDetail?.investor_id,
            ratings: {
                problem: values?.problem,
                market: values?.market,
                product: values?.product,
                team: values?.team,
                traction: values?.traction
            },
            investible: values?.invest,
            feedback: values?.feedback
        }
        SaveFeedbackAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const saveUserType = (first_name, last_name, type) => {
        let request = {
            first_name: first_name?.trim(),
            last_name: last_name?.trim(),
            type: type
        }
        SaveUserTypeAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    useEffect(() => {
        if (savePersonalInfoResponse && savePersonalInfoResponse?.data?.statusCode == 200) {
            handleNamePopupClose()
            refetchProfileDetails()
        } else {
            savePersonalInfoResponse?.data?.message && toast.error(<CustomToast message={savePersonalInfoResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
    }, [savePersonalInfoResponse?.data?.statusCode]);

    useEffect(() => {
        if (saveToListResponse && saveToListResponse?.data?.statusCode == 200) {
            toast.success(<CustomToast message={saveToListResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        } else {
            saveToListResponse?.data?.message && toast.error(<CustomToast message={saveToListResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
        handleNamePopupClose()
        setButtonClickType(null)
        // refetchFullStartupDetails()
        fetchStatusOfButtons()
    }, [saveToListResponse?.data?.statusCode]);

    useEffect(() => {
        if (saveInterestResponse && saveInterestResponse?.data?.statusCode == 200) {
            toast.success(<CustomToast message={saveInterestResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        } else {
            saveInterestResponse?.data?.message && toast.error(<CustomToast message={saveInterestResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
        handleInterestPopupClose()
        setButtonClickType(null)
        // refetchFullStartupDetails()
        fetchStatusOfButtons()
    }, [saveInterestResponse?.data?.statusCode]);

    useEffect(() => {
        if (saveFeedbackResponse && saveFeedbackResponse?.data?.statusCode == 200) {
            toast.success(<CustomToast message={saveFeedbackResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        } else {
            saveFeedbackResponse?.data?.message && toast.error(<CustomToast message={saveFeedbackResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
        handleFeedbackPopupClose()
        setButtonClickType(null)
        // refetchFullStartupDetails()
        fetchStatusOfButtons()
    }, [saveFeedbackResponse?.data?.statusCode]);

    useEffect(() => {
        if (saveUserTypeResponse && saveUserTypeResponse?.data?.statusCode == 200) {
            toast.success(<CustomToast message={saveUserTypeResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        } else {
            saveUserTypeResponse?.data?.message && toast.error(<CustomToast message={saveUserTypeResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
        handleTypePopupClose()
        redirectToDashdoard()
        setButtonClickType(null)
        // refetchFullStartupDetails()
        fetchStatusOfButtons()
    }, [saveUserTypeResponse?.data?.statusCode]);

    useEffect(() => {
        if (fetchStatusResponse && fetchStatusResponse?.data?.statusCode == 200) {
            setEnableInterest(fetchStatusResponse?.data?.investorInfo == null)
            setEnableFeedback(fetchStatusResponse?.data?.feedback == null)
        } else {
            // fetchStatusResponse?.data?.message && toast.error(<CustomToast message={fetchStatusResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            //     duration: 4000,
            //     position: "top-right",
            //     style: {
            //         padding: "15px 30px",
            //     },
            // });
        }
    }, [fetchStatusResponse?.data?.statusCode]);

    const redirectToDashdoard = () => {
        if (type != null) {
            if (type == "Investor") {
                window.location.assign("https://backrr.in/dashboard")
            } else {
                window.location.assign("https://backrr.in/dashboard")
            }
        }
    }

    const ButtonsRow = () => {
        return <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
            {/* <B300 onClick={addToMyList} startIcon={<Add fontSize='1.25rem' />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px' }}>
                Add to my list
            </B300> */}
            <B300 onClick={handleInterestPopupOpen} startIcon={<ThumbUp sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
                Show Interest
            </B300>
            <B300 onClick={handleFeedbackPopupOpen} startIcon={<RateReview sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
                Submit Feedback
            </B300>
        </Grid>
    }

    useEffect(() => {
        setPageWidth(document.getElementById('pdf_container')?.parentNode.clientWidth)
    }, [width])

    return (
        <AuthWrapper1 sx={{ height: '100%' }}>
            <MainWrappper profileData={userDetail} startupData={fullStartupDetailsData} theme={theme}>
                <Helmet>
                    <title>{fullStartupDetailsData?.data != null ? fullStartupDetailsData?.data?.startup_name + "-Pitch Deck | Backrr" : "Backrr"}</title>
                    <meta name="description" content={fullStartupDetailsData?.data?.problem_solving} />
                    <meta property="og:title" content={fullStartupDetailsData?.data != null ? fullStartupDetailsData?.data?.startup_name + "-Pitch Deck | Backrr" : "Backrr"} />
                    <meta property="og:description" content={fullStartupDetailsData?.data?.problem_solving} />
                </Helmet>

                <main>
                    <Grid position={'fixed'} right={10} top={10} zIndex={100}>
                        <IconButton onClick={handleLogOut}><Logout /></IconButton>
                    </Grid>
                    <Grid xs={12} container display={"block"} padding={{ xs: 0, sm: "2rem" }} sx={{
                        maxWidth: '900px !important',
                        marginInline: 'auto',
                        // height: '100%',
                        paddingTop: '0.5rem !important',
                        // maxHeight: '80vh'
                    }}>
                        <Grid container xs={12} alignItems={"center"} spacing={2} pb={0.5} marginInline={"auto"} position={"sticky"} top={0} bgcolor={"#FFFAF5"} sx={{ zIndex: 1 }}>
                            <Grid item xs={12} pl={"0 !important"}>
                                {isFullStartupDetailsLoading ?
                                    <div className='skeleton' style={{ width: "6rem", height: "6rem", borderRadius: '3rem', marginInline: 'auto', display: "block" }} />
                                    : <>
                                        {
                                            showInitialLogo ? <button type='button' onClick={() => { navigate(`/startup/${slug}`) }} style={{ backgroundColor: 'transparent', padding: 0, marginInline: 'auto', display: "block", }}><div style={{ width: "5rem", height: "5rem", borderRadius: '1.4rem', justifyContent: 'center', alignItems: 'center', backgroundColor: "#007bff", alignContent: 'center' }}>
                                                <Typography variant='h1' sx={{ fontSize: { xs: "0.5rem", md: "0.75rem" }, textAlign: 'center', marginInline: 'auto' }} color={'white'}>{startupDetail?.startup_name?.split(" ")[0]?.substring(0, 1)}{startupDetail?.startup_name?.split(" ")[1]?.substring(0, 1)}</Typography>
                                            </div></button>
                                                : <button type='button' onClick={() => { navigate(`/startup/${slug}`) }} style={{ backgroundColor: 'transparent', padding: 0, marginInline: 'auto', display: "block" }}><img src={startupDetail != null ? startupDetail?.startup_logo_path : null} onLoad={() => { setShowInitialLogo(false) }} onError={() => { setShowInitialLogo(true) }} style={{ width: "5rem", height: "5rem", borderRadius: '5px', }} /></button>
                                        }
                                    </>}
                            </Grid>
                            <Grid xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                {isFullStartupDetailsLoading ? <Box className="skeleton" height={"1.25rem"} width={"min-content"} sx={{ color: 'transparent', marginTop: '0.5rem' }}>skeleton</Box> : startupDetail?.startup_name && <button type='button' onClick={() => { navigate(`/startup/${slug}`) }} style={{ backgroundColor: 'transparent', padding: 0 }}><Typography variant='title' sx={{ fontSize: { xs: "1rem", md: "1rem" }, textAlign: 'center', marginInline: 'auto' }}>{startupDetail?.startup_name}</Typography></button>}
                            </Grid>
                        </Grid>
                        <Grid xs={12} alignItems={"center"} spacing={2} marginInline={"auto"} display={"flex"} height={"100%"}>
                            <Grid item sx={{
                                width: '100%', minHeight: "40rem", height: '100%', marginTop: "1rem", borderRadius: '8px', padding: { xs: 0, md: "0 2rem !important" },
                                //  border: `1px solid ${theme.palette.customColors.g75}`,
                                gap: "1rem"
                            }}>
                                <ButtonsRow />
                                
                                {
                                    pdfLoading  ?
                                        <Grid container item xs={12} md={12} height={"55vh"} marginInline={"auto"} display={"flex"} alignItems={"center"} justifyContent={"center"} spacing={2} marginTop={3} paddingRight={2}>
                                            <Box className='skeleton' height={"calc(100%)"} marginTop={2} sx={{ padding: '1rem', width: '100%' }}>
                                                <div class="viewer pdf" />
                                            </Box>
                                        </Grid> :
                                         
                                        <Grid xs={12} md={12} marginInline={"auto"} display={"flex"} alignItems={"center"} justifyContent={"center"} spacing={2} marginTop={2} paddingRight={{ xs: 0, md: 2 }} height={"calc(100vh - 160px)"} maxHeight={"460px"} >
                                            <Card sx={{ padding: { xs: 0, md: '1rem' }, boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 1, width: '100%', position: 'relative'}}>

                                                <Grid item container xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <IconButton onClick={() => { navigate(`/startup/${slug}`) }}>
                                                        <ArrowBack style={{ height: 25, width: 25, color: theme.palette.customColors.indigo600 }} />
                                                    </IconButton>
                                                    <IconButton onClick={() => { setOpenFullScreen(true) }}>
                                                        {/* <div className='maximize-icon'></div> */}
                                                        <FullscreenIcon style={{ height: 25, width: 25, color: theme.palette.customColors.indigo600 }} />
                                                    </IconButton>
                                                </Grid>
                                                {/* {!loadError && <Grid position={'absolute'} left={{ xs: 5, md: 15 }} zIndex={100}> */}

                                                {/* </Grid>} */}
                                                {/* {!loadError && <Grid position={'absolute'} right={{ xs: 5, md: 15 }} zIndex={100}> */}

                                                {/* </Grid>} */}
                                                {pdfUrl?.trim()?.length > 0 &&
                                                    // <iframe onLoad={() => { setPdfLoading(false) }} src={`//docs.google.com/viewer?embedded=true&url=${pdfUrl}`} style={{ width: '100%', height: '55vh', border: '0px' }}>
                                                    // </iframe>
                                                    // <div style={{ overflow: 'scroll', height: '55vh', border: `1px solid ${theme.palette.customColors.gray300}` }}>
                                                    //     <Document onLoadError={() => { setLoadError(true) }} error={() => { return <NoData /> }} file={pdfUrl} onLoadSuccess={({ numPages }) => { setNumPages(numPages); setLoadError(false) }}>
                                                    //         <div id='pdf_container' style={{ backgroundColor: theme.palette.customColors.gray300 }}>
                                                    //             {Array.apply(null, Array(numPages))
                                                    //                 .map((x, i) => i + 1)
                                                    //                 .map((page, index) => (
                                                    //                     <div id='page_view' style={{ marginBottom: index != numPages - 1 ? '0.7rem' : 0 }}>
                                                    //                         <Page
                                                    //                             key={page}
                                                    //                             pageNumber={page}
                                                    //                             renderTextLayer={false}
                                                    //                             renderAnnotationLayer={false}
                                                    //                             scale={1}
                                                    //                             width={pageWidth > 0 ? pageWidth : document.getElementById('pdf_container')?.parentNode.clientWidth}
                                                    //                         />
                                                    //                     </div>
                                                    //                 ))}
                                                    //         </div>
                                                    //     </Document>
                                                    // </div>
                                                    <div style={{ border: `1px solid ${theme.palette.customColors.gray300}`,borderRadius: '8px' , overflow: 'hidden' }}>
                                                        <Document onLoadError={() => { setLoadError(true) }} loading={<Box className="skeleton" height={"calc(100vh - 160px)"} maxHeight={"406px"} width={"100%"}></Box>} error={() => { return <NoData /> }} file={pdfUrl} onLoadSuccess={({ numPages }) => { setNumPages(numPages); setLoadError(false) }} >
                                                            <div id='pdf_container' style={{ backgroundColor: theme.palette.customColors.gray300 }}>
                                                                <Page
                                                                    pageNumber={pageNumber}
                                                                    renderTextLayer={false}
                                                                    renderAnnotationLayer={false}
                                                                    scale={1}
                                                                    loading={<Box className="skeleton" height={"calc(100vh - 160px)"} maxHeight={"406px"}  width={"100%"}></Box>}
                                                                    width={pageWidth > 0 ? pageWidth : document.getElementById('pdf_container')?.parentNode.clientWidth}
                                                                />
                                                            </div>
                                                        </Document>
                                                    </div>
                                                }
                                                {!loadError && <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1} mt={2}>
                                                    <IconButton disabled={pageNumber <= 1} disableRipple={false} sx={{ padding: 0 }}>
                                                        <ChevronLeftIcon sx={{ color: pageNumber <= 1 ? 'inherit' : theme.palette.customColors.indigo600 }} width={50} height={50} onClick={() => setPageNumber(prevPageNumber => Math.max(1, prevPageNumber - 1))} />
                                                    </IconButton>
                                                    <Typography variant='title' sx={{ fontSize: { xs: "0.5rem", md: "0.75rem" }, textAlign: 'center', marginX: 5 }}>{pageNumber}/{numPages}</Typography>
                                                    <IconButton disabled={pageNumber >= numPages} disableRipple={false} sx={{ padding: 0 }}>
                                                        <ChevronRightIcon sx={{ color: pageNumber >= numPages ? "inherit" : theme.palette.customColors.indigo600 }} width={50} height={50} onClick={() => setPageNumber(prevPageNumber => Math.min(numPages, prevPageNumber + 1))} />
                                                    </IconButton>
                                                </Grid>}
                                            </Card>
                                        </Grid>
                                }


                            </Grid>

                        </Grid>
                    </Grid>
                    <Dialog fullScreen={true} open={openFullScreen} onClose={() => { setOpenFullScreen(false) }} sx={{}}>
                        <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.customColors.gray300}` }}>
                            <Grid sx={{ position: 'sticky', padding: "0", backgroundColor: '#FFF', zIndex: 1, marginBottom: '0.5rem' }} display={'flex'} alignItems={'center'}>
                                <Grid style={{ flex: 1 }}>
                                    <Typography fontWeight={700} fontSize={"1.3rem"} textAlign={'center'}>Pitch Deck</Typography>
                                </Grid>
                                <IconButton disableRipple={true} sx={{ padding: 0 }}>
                                    <CloseIcon width={40} height={40} style={{ marginRight: "-15px" }} onClick={() => { setOpenFullScreen(false) }} />
                                </IconButton>
                            </Grid>
                            <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} gap={2}>
                                <B300 onClick={handleInterestPopupOpen} startIcon={<ThumbUp sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
                                    Show Interest
                                </B300>
                                <B300 onClick={handleFeedbackPopupOpen} startIcon={<RateReview sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
                                    Submit Feedback
                                </B300>
                            </Grid >
                        </DialogTitle>
                        <DialogContent sx={{ padding: 0 }}>
                            <div style={{ overflow: 'scroll', height: '85vh', border: `1px solid ${theme.palette.customColors.gray300}` }}>
                                <Document error={() => { return <NoData /> }} file={pdfUrl} onLoadSuccess={({ numPages }) => { setNumPages(numPages) }}>
                                    <div id='pdf_container2' style={{ backgroundColor: theme.palette.customColors.gray300, width: '100%' }}>
                                        {Array.apply(null, Array(numPages))
                                            .map((x, i) => i + 1)
                                            .map((page, index) => (
                                                <div id='page_view' style={{ marginBottom: index != numPages - 1 ? '0.7rem' : 0 }}>
                                                    <Page
                                                        key={page}
                                                        pageNumber={page}
                                                        renderTextLayer={false}
                                                        renderAnnotationLayer={false}
                                                        scale={1}
                                                        width={window.innerWidth}
                                                    // width={pageWidth > 0 ? pageWidth : document.getElementById('pdf_container2')?.parentNode.clientWidth}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </Document>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <ModalComponent open={openNamePopup} onClose={() => { }} style={{ maxWidth: 640, width: "100%" }}>
                        <NameInvestorPopup handleClose={handleNamePopupClose} handleSubmit={handleNameInvestor} />
                    </ModalComponent>
                    <ModalComponent open={openInterestPopup} onClose={handleInterestPopupClose} style={{ maxWidth: 640, width: "100%" }}>
                        <SaveInterestPopup data={userDetail} handleClose={handleInterestPopupClose} handleSubmit={handleInterestSave} />
                    </ModalComponent>
                    <ModalComponent open={openFeedbackPopup} onClose={handleFeedbackPopupClose} style={{ maxWidth: 640, width: "100%" }}>
                        <FeedbackPopup data={userDetail} handleClose={handleFeedbackPopupClose} handleSubmit={handleFeedbackSave} />
                    </ModalComponent>
                    <ModalComponent open={openTypePopup} onClose={handleTypePopupClose} style={{ maxWidth: 640, width: "100%" }}>
                        <UserTypePopup data={userDetail} handleClose={handleTypePopupClose} handleSubmit={handleUserType} />
                    </ModalComponent>
                    <AlertDialog open={showAlert} handleClose={() => { setShowAlert(false); setAlertMessage(null); setAlertHeading(null) }} heading={alertHeading} subheading={alertMessage} />
                </main>
            </MainWrappper>
        </AuthWrapper1>
    )
}

export default PitchDeck
import InvestorDetail from '../views/investorDetail';
import Accelerator from '../views/investorDetail/Accelerator';
import InvestmentBanker from '../views/investorDetail/IB';
import Incubator from '../views/investorDetail/Incubator';
import InvestorDetail2 from '../views/investorDetail/InvestorDetail2';
import MicroVC from '../views/investorDetail/MicroVC';
import VC from '../views/investorDetail/VC';
import LoginScreenInvestor from '../views/pagesInvestor/LoginScreenInvestor';
import OTPScreenInvestor from '../views/pagesInvestor/OTPScreenInvestor';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutesInvestors = {
    path: '',
    children: [
        {
            path: '/',
            element: <LoginScreenInvestor />,
        },
        {
            path: '/login',
            element: <LoginScreenInvestor />,
        },
        {
            path: '/verification',
            element: <OTPScreenInvestor />,
        },
        {
            path: '*',
            element: <LoginScreenInvestor />,
        },
        {
            path: '/:slug',
            element: <InvestorDetail />
        },
        {
            path: '/microvc',
            element: <MicroVC />
        },
        {
            path: '/vc',
            element: <VC />
        },
        {
            path: '/ib',
            element: <InvestmentBanker />
        },
        {
            path: '/incubator',
            element: <Incubator />
        },
        {
            path: '/accelerator',
            element: <Accelerator />
        },
        {
            path: '/pranaymathur2',
            element: <InvestorDetail2 />
        },
    ]
};

export default AuthenticationRoutesInvestors;

import PitchDeck from '../views/PitchDeck';
import Error404 from '../ui-components/Error404';
import DataRoom from '../views/DataRoom';
import DocumentView from '../views/DataRoom/Document';
import InvestorDetail from '../views/investorDetail';
import InvestorDetail2 from '../views/investorDetail/InvestorDetail2';
import Accelerator from "../views/investorDetail/Accelerator";
import InvestmentBanker from "../views/investorDetail/IB";
import Incubator from "../views/investorDetail/Incubator";
import MicroVC from "../views/investorDetail/MicroVC";
import VC from "../views/investorDetail/VC";

// // login option 3 routing
// const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const CreatProfileRoutesInvestors = {
    path: '',
    children: [
        {
            path: '/',
            element: <Error404 />
        },
        {
            path: '',
            element: <Error404 />
        },
        {
            path: '*',
            element: <Error404 />
        },
        {
            path: '/:slug',
            element: <InvestorDetail />
        },
        {
            path: '/microvc',
            element: <MicroVC />
        },
        {
            path: '/vc',
            element: <VC />
        },
        {
            path: '/ib',
            element: <InvestmentBanker />
        },
        {
            path: '/incubator',
            element: <Incubator />
        },
        {
            path: '/accelerator',
            element: <Accelerator />
        },
        {
            path: '/pranaymathur2',
            element: <InvestorDetail2 />
        },

        {
            path: '/:slug/d/pitchdeck',
            element: <PitchDeck />
        },

        {
            path: '/:slug/d',
            element: <DataRoom />
        },

        {
            path: '/:slug/d/:title',
            element: <DocumentView />
        },
    ]
};

export default CreatProfileRoutesInvestors;

import { Grid, IconButton, Modal } from '@mui/material';
import React, { useState } from 'react'
import ImageGallery from "react-image-gallery";
import { ReactComponent as CloseIcon } from "../assets/close_icon_white.svg";

export default function CustomGallery({ imagesArray = [], theme }) {

    const [openImage, setOpenImage] = useState(null)

    return (
        <>
            <Grid
                container
                display={'flex'}
                xs={12}
                sx={{
                    overflow: "hidden",
                    justifyContent: 'start',
                    alignItems: 'center',
                }}
            >
                {
                    imagesArray?.map((item, index) => {
                        return (
                            <Grid item width={{ xs: "100%", sm: "50%", md: "50%", lg: "33.33%", xl: "25%" }} padding={"0.5rem"}>
                                <Grid sx={{ border: `1px solid ${theme.palette.customColors.g75}`, cursor: 'pointer', borderRadius: "8px" }} alignItems={'center'} justifyContent={'center'} onClick={() => { setOpenImage(index) }}>
                                    <img src={item.original} style={{ width: "100%", height: "10rem", marginInline: 'auto', display: "block", objectFit: 'contain' }} />
                                </Grid>
                            </Grid>
                            // <Grid onClick={() => { setOpenImage(index) }} display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', justifyContent: 'center', alignItems: 'center', cursor: "pointer" }}>
                            //     <Grid>
                            //         <img src={item.original} style={{ width: "10rem", height: "10rem", borderRadius: '8px', marginInline: 'auto', display: "block", objectFit: 'fill' }} />
                            //     </Grid>
                            // </Grid>
                        )
                    })
                }
            </Grid>
            <Modal open={openImage != null} onClose={() => { setOpenImage(null) }}>
                <div>
                    <Grid position={'fixed'} right={10} top={10} zIndex={100}>
                        <IconButton onClick={() => { setOpenImage(null) }}><CloseIcon /></IconButton>
                    </Grid>
                    <Grid xs={12} alignItems={'center'} justifyContent={'center'} height={"100vh"} sx={{ backgroundColor: "#000" }}>
                        {/* <img src={openImage?.original} style={{ width: "100%", height: "100%", objectFit: 'contain' }} /> */}
                        <ImageGallery
                            items={imagesArray}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            startIndex={openImage}
                            slideInterval={2000}
                        />
                    </Grid>
                </div>
            </Modal>
        </>
    )
}
